.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(50%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateY(0);
}
